import React, { useEffect, useState } from "react";
import "./home.css";
import GalleryCards from "../../components/GalleryCards";
import Navbar from "../../components/Navbar";
import VideoGallery from "../../components/VideoGallery";
import Aos from "aos";
import "aos/dist/aos.css";
import Contact_Location from "../../components/contact/Contact_Location";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import Loader from "../../components/Loader/Loader";
import AwardsAndBrands from "../Awards&Brands/AwardsAndBrands";
import { useNavigate } from "react-router-dom";
import workImages from '../../services/workImages.json'
function Home() {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(true)

  setTimeout(() => {
    setLoading(false)
  }, 2000)

  useEffect(() => {
    Aos.init({
      duration: 1000,
    });
  }, []);
  useEffect(() => {
    const handleScroll = () => {
      const svgCurve = document.getElementById("svg-curve");
      if (svgCurve) {
        if (window.scrollY > 100) {
          svgCurve.classList.add("visible");
        } else {
          svgCurve.classList.remove("visible");
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);


  const onImageBtnClick = () => {
    let workName = 'All'
    const clickedWorkName = workImages.find(data => data.workName === workName)
    if (clickedWorkName) {
      navigate(`/works/${workName.toLowerCase().replace(/\s+/g, "-")}`, {
        state: { workName: clickedWorkName },
      });
    }
  }
  return (
    <>

      {loading && <Loader />}
      {/* Landing  */}
      <div className="home-container">
        <Navbar />
       
            <div className="welcome-screen" id="/">
              <div className="tagline font-large text-dark">
                <p>We help brands and companies stand out in the digital age</p>
              </div>
            </div>
            {/* Images */}
            <div style={{ height: "120px", background: "#ffc114" }}>
              <div id="svg-curve" class="custom-shape-divider-bottom-1717681059">
                <svg
                  data-name="Layer 1"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 1200 120"
                  preserveAspectRatio="none"
                >
                  <path
                    d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
                    class="shape-fill"
                  ></path>
                </svg>
              </div>
            </div>
          
        
        <div className="img-container d-flex position-relative flex-column">
          <div className="image-gallery">
            <h1 className="text-right">GALLERY</h1>
            <GalleryCards page="home" />
            <button className="view-more-btn" onClick={onImageBtnClick}>
              <div className="view-more-btn-text">
                <p>View More</p>
              </div>
              <FontAwesomeIcon icon={faAngleRight} className="icon-right" />
            </button>
          </div>
        </div>
        {/* Video */}
        <div className="home-video-gallery" data-aos="fade-up">
          <VideoGallery />
        </div>
        {/* Awards and brans */}
        <div className="awards-section" id="awards">
          <AwardsAndBrands />
        </div>
        {/* contacts */}
        <div id="contact-section" className="contact_section" data-aos="fade-up">
          <Contact_Location />
        </div>
      </div>

    </>
  );
}

export default Home;
