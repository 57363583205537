import React from "react";
import "../styles/gallerycards.css";
import galleryImages from "../services/works.json";
import brandImages from "../services/brand_logo.json";

function GalleryCards({ page }) {
  const chunkImages = (images, size, limit) => {
    const chunks = [];
    for (let i = 0; i < Math.min(images.length, limit); i += size) {
      chunks.push(images.slice(i, i + size));
    }
    return chunks;
  };

  const imageRows =
    page === "home"
      ? chunkImages(galleryImages, 2, 8)
      : chunkImages(brandImages, 4, 16);

  return (
    <>
      {page === "home" && (
        <section className="gallery gradient-background">
          {imageRows.map((row, rowIndex) => (
            <div key={rowIndex}>
              {row.map((image, index) => {
                let imagePath = require(`../assets/images/${image.src}`);
                return (
                  <article key={index}>
                    <img src={imagePath} alt={image.alt} />
                  </article>
                );
              })}
            </div>
          ))}
        </section>
      )}

      {page === "brand" && (
        <section className="brand gradient-background">
          {imageRows.map((row, rowIndex) => (
            <div key={rowIndex}>
              {row.map((image, index) => {
                let imagePath = require(`../assets/brand_logo/${image.src}`);
                return (
                  <article key={index}>
                    <img src={imagePath} alt={image.alt} />
                  </article>
                );
              })}
            </div>
          ))}
        </section>
      )}  
    </>
  );
}

export default GalleryCards;
