import React, { useState, useEffect } from 'react';
import "../styles/videogallery.css";

const VideoGallery = () => {
  const videoIds = [
    'Cfql-fPwrmk',
    'Gtp9OI5Pz4c',
    'O4CD2aGlsx8',
    '7zYbehcC8xY'
  ];


  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
  const [nextVideoIndex, setNextVideoIndex] = useState(1);
  const [isTransitioning, setIsTransitioning] = useState(false);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setIsTransitioning(true);
      setTimeout(() => {
        setCurrentVideoIndex(nextVideoIndex);
        setNextVideoIndex((prevIndex) => (prevIndex + 1) % videoIds.length);
        setIsTransitioning(false);
      }, 1000);
    }, 15000);

    return () => clearInterval(intervalId);
  }, [nextVideoIndex, videoIds.length]);

  const currentVideoId = videoIds[currentVideoIndex];
  const nextVideoId = videoIds[nextVideoIndex];
  const videoUrl = `https://www.youtube.com/embed/${currentVideoId}?autoplay=1&mute=1`;
  const nextVideoUrl = `https://www.youtube.com/embed/${nextVideoId}?autoplay=1&mute=1`;
  const navigateToYoutube = () => {
    window.open(`https://www.youtube.com/watch?v=${currentVideoId}`, '_blank');
  };

  return (
    <div className="video-gallery" onClick={navigateToYoutube}>
      <div className="overlay">
        {/* <p className='concept'>From Concept to Conversion</p> */}
      </div>

      <div className={`video-container current ${isTransitioning ? 'transitioning' : ''}`}>

        <iframe
          src={videoUrl}
          title="YouTube video"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          className="video-iframe"
        ></iframe>
      </div>
      <div className={`video-container next ${isTransitioning ? 'transitioning' : ''}`}>
        <iframe
          src={nextVideoUrl}
          title="Next YouTube video"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          className="video-iframe"
        ></iframe>
      </div>
    </div>
  );
};

export default VideoGallery;
