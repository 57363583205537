import React, { useState, useEffect } from "react";
import aboutBanner from "../../assets/images/OFFICE 01.jpg";
import styles from "./aboutsection.module.css";
import Loader from "../../components/Loader/Loader";
import banner1 from '../../assets/images/OFFICE 02.jpg'
import banner2 from '../../assets/images/OFFICE 03 (1).jpg'

function AboutSection() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000);
    return () => clearTimeout(timer); 
  }, []);

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="d-flex flex-column bg-red h-100">
      <div
        className={`${styles.banner} position-relative d-flex align-items-center justify-content-center`}
        style={{ height: "50vh" }}
      >
        <div
          className={`${styles.overlay} position-absolute w-100 h-100`}
        ></div>
        <img
          className={`${styles.bannerImage} position-absolute w-100`}
          src={aboutBanner}
          alt="Service Banner"
        />
        <div className={`${styles.title} position-absolute`}>
          About Us
        </div>
      </div>

      {/* title -description-services */}
      <div className={`${styles.title_description_services}`}>
        <div className={`${styles.desc_container}`}>
          <div
            className={`${styles.description} d-flex justify-content-center `}
          >
            Relations Media is a 15-year- old company started by Nikhil N, a
            visionary who believes that personalisation and commitment are key
            when working with a client. We work with promising start-ups as
            well as leading brands to create a winning strategy with our
            experience and expertise in the industry.
          </div>
          {/* who we are */}
          <div className={`${styles.services_images_list} mt-5`}>
            <div className={`d-flex ${styles.li_div} text-white`}>
              <h1>WHO WE ARE</h1>
              <p>
                Relations Media is a 15-year- old company started by Nikhil N,
                a visionary who believes that personalization and commitment
                are key when working with a client. We work with promising
                start-ups as well as leading brands to create a winning
                strategy with our experience and expertise in the industry. A
                360 degree branding/creative/marketing agency, which builds
                brands that become an indispensable part of society, Relations
                Media is the bridge that connects design and communication.
                Our company has recently launched an infotainment channel on
                YouTube, called Relations Media Broadcasting. Apart from
                videos of our events/events that we are associated with, we
                have our very own Wonder Women, a programme that is dedicated
                to women who are on the move. Coming up soon is Campus Chorus:
                Paadam Nammuku Paadam, a campus based musical reality show.
              </p>
            </div>
            <div className={`${styles.imageContainer} ${styles.we}`}>
              <img
                src={banner1}
                className={`${styles.image}`}
                alt="Service"
              />
            </div>
          </div>
          {/* {what guide us} */}
          <div className={`${styles.services_images_list} mt-5`}>
            <div className={`${styles.imageContainer} ${styles.guide}`}>
              <img
                src={banner2}
                className={`${styles.image}`}
                alt="Service"
              />
            </div>
            <div className={` d-flex ${styles.li_div} text-white`}>
              <h1>OUR VISION</h1>
              <p>
                To deliver the WOW factor to our services by providing innovative and effective brand marketing and public relations solutions that help our clients develop and grow their businesses.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutSection;
