import React, { useState, useEffect } from 'react';
import data from '../services/brand_logo.json'

const ImageSlider = () => {
  const [currentBrandIndex, setCurrentBrandIndex] = useState(0);
  const [fade, setFade] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      setFade(false);
      setTimeout(() => {
        setCurrentBrandIndex((prevIndex) => (prevIndex + 1) % data.length);
        setFade(true);
      }, 500); 
    }, 5000);
    return () => clearInterval(interval);
  }, [data.length]);

  const currentBrand = data[currentBrandIndex];

  return (
    <div className={`slider ${fade ? 'fade-in' : 'fade-out'}`}>
      <style>
        {`
          .slider {
            text-align: center;
            transition: opacity 0.5s ease-in-out;
          }
          .fade-in {
            opacity: 1;
          }
          .fade-out {
            opacity: 0;
          }
          .brandName {
            font-size: 2em;
            margin-bottom: 20px;
            color: rgb(255, 192, 29);
          }
          .imagesContainer {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
          }
          .imageItem {
            margin: 10px;
            text-align: center;
          }
          .image {
            width: 300px;
            max: 350px;
          }
          .imageInfo {
            margin-top: 10px;
          }
        `}
      </style>
      <h2 className="brandName">{currentBrand.name}</h2>
      <div className="imagesContainer">
        {currentBrand.images.map((image, index) => (
          <div key={index} className="imageItem">
            <img src={require(`../assets/brand_logo/${image.src}`)} alt={image.alt} className="image" />
          </div>
        ))}
      </div>
    </div>
  );
};

export default ImageSlider;
