import React from 'react'
import './loader.css'

function Loader() {
  return (
   <>
   <div className='loader-container'>
   <div class="loader"></div>
   </div>
   </>
  )
}

export default Loader