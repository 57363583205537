import React, { useState } from "react";
import styles from "./careers.module.css";
import Loader from "../../components/Loader/Loader";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function Careers() {
  const [loading, setLoading] = useState(true);
  const [fileName, setFileName] = useState("");

  setTimeout(() => {
    setLoading(false);
  }, 2000);

  const handleFileChange = (event) => {
    setFileName(event.target.files[0]?.name || "");
  };

  const handleClick = () => {
    window.open("https://forms.gle/XFxvNaGmxCrAQJJb6", "_blank");
  };

  return (
    <>
      {loading && <Loader />}
      <div className={styles.careersContainer}>
        <div className={styles.overlay}></div>
        <div className={styles.content}>
          <h1 className={styles.title}>Join Our Journey</h1>
          <form className={styles.form}>
            <div className={styles.inputBox}>
              <button
                type="submit"
                className={styles.view_more_btn}
                onClick={handleClick}
              >
                <div className={styles.view_more_btn_text}>
                  <p>CONNECT NOW</p>
                </div>
                <FontAwesomeIcon icon={faAngleRight} className={styles.icon_right}/>
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default Careers;
