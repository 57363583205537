import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "../styles/menu.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import detailedServices from '../services/detailedServices.json';
import worksImages from '../services/workImages.json'

function Menu({ onClose, isOpen }) {
  const [isServicesOpen, setIsServicesOpen] = useState(false);
  const [isWorksOpen, setIsWorksOpen] = useState(false)
  const navigate = useNavigate();

  const toggleServices = () => {
    setIsServicesOpen(!isServicesOpen);
  };

  const toggleWorks = () => {
    setIsWorksOpen(!isWorksOpen);
  };

  const onCardClick = (title) => {
    const clickedServiceData = detailedServices.find(
      (data) => data.title === title
    );
    if (clickedServiceData) {
      navigate(`/services/${title.toLowerCase().replace(/\s+/g, "-")}`, {
        state: { service: clickedServiceData },
      });
      onClose(); // Close the menu
    }
  };

  const handleWorkClick = (workName) => {
    const clickedWorkName = worksImages.find(data =>  data.workName === workName )
    if (clickedWorkName) {
      navigate(`/works/${workName.toLowerCase().replace(/\s+/g, "-")}`, {
        state: { workName: clickedWorkName },
      });
      onClose(); // Close the menu
    }
  };

  return (
    <div className={`menu-container ${isOpen ? 'open' : ''} d-flex flex-column align-items-start p-3`}>
      <button className="close-btn" onClick={onClose}>
        <FontAwesomeIcon icon={faTimes} />
      </button>
      <ul className="w-100 menu-list mt-4">
        <li><Link to='/about' className="white-link" onClick={onClose}>About Us</Link></li>
        <li onClick={toggleServices} className={`services-menu ${isServicesOpen ? 'open' : ''} ${isServicesOpen ? 'highlight' : ''}`}>
          <span className={`white-link ${isServicesOpen ? 'highlight' : ''}`}>Services</span>
          <FontAwesomeIcon icon={isServicesOpen ? faChevronUp : faChevronDown} className="icon-spacing" />
          <ul className={`nested-list ${isServicesOpen ? 'open' : ''}`}>
            {detailedServices.map(service => (
              <li key={service.title} onClick={() => onCardClick(service.title)}>{service.title}</li>
            ))}
          </ul>
        </li>
        <li onClick={toggleWorks} className={`services-menu ${isWorksOpen ? 'open' : ''} ${isWorksOpen ? 'highlight' : ''}`}>
          <span className={`white-link ${isWorksOpen ? 'highlight' : ''}`}>Works</span>
          <FontAwesomeIcon icon={isWorksOpen ? faChevronUp : faChevronDown} className="icon-spacing" />
          <ul className={`nested-list ${isWorksOpen ? 'open' : ''}`}>
            {worksImages.map(work => (
              <li key={work.id} onClick={() => handleWorkClick(work.workName)}>
                {work.workName}
              </li>
            ))}
          </ul>
        </li>
        <li><HashLink to="/#awards" className="white-link" onClick={onClose}>Awards</HashLink></li>
        <li><Link to="/story" className="white-link" onClick={onClose}>Our Story</Link></li>
        <li><HashLink to="/#contact-section" className="white-link" onClick={onClose}>Contact Us</HashLink></li>
        <li><Link to="/careers" className="white-link" onClick={onClose}>Careers</Link></li>
      </ul>
    </div>
  );
}

export default Menu;
