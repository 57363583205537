import React, { useState, useRef, useEffect } from "react";
import styles from "./awards.module.css";
import awardsImages from '../../services/awards.json';
import brandImages from '../../services/brands.json';

function AwardsAndBrands() {
  const [isHovered, setIsHovered] = useState(false);
  const carouselRef = useRef(null);
  const carouselAwardsRef = useRef(null);

  useEffect(() => {
    const carouselAwards = carouselAwardsRef.current;

    const handleMouseEnter = () => {
      carouselAwards.style.animationPlayState = "paused";
      setIsHovered(true);
    };

    const handleMouseLeave = () => {
      carouselAwards.style.animationPlayState = "running";
      setIsHovered(false);
    };

    carouselAwards.addEventListener("mouseenter", handleMouseEnter);
    carouselAwards.addEventListener("mouseleave", handleMouseLeave);

    return () => {
      carouselAwards.removeEventListener("mouseenter", handleMouseEnter);
      carouselAwards.removeEventListener("mouseleave", handleMouseLeave);
    };
  }, []);

  useEffect(() => {
    const carousel = carouselRef.current;

    const handleMouseEnter = () => {
      carousel.style.animationPlayState = "paused";
      setIsHovered(true);
    };

    const handleMouseLeave = () => {
      carousel.style.animationPlayState = "running";
      setIsHovered(false);
    };

    carousel.addEventListener("mouseenter", handleMouseEnter);
    carousel.addEventListener("mouseleave", handleMouseLeave);

    return () => {
      carousel.removeEventListener("mouseenter", handleMouseEnter);
      carousel.removeEventListener("mouseleave", handleMouseLeave);
    };
  }, []);

  console.log('Brand Images:', brandImages);

  return (
    <div className={styles.container}>
      {/* Brands */}
      <div className={styles.brands}>
        <h1>OUR CLIENTS</h1>
        <div className={styles.carouselContainer}>
          <div ref={carouselRef} className={styles.carousel}>
            {brandImages.map((image, index) => (
              <div key={index} className={styles.imageContainer}>
                <img
                  src={require(`../../assets/brands/${image}`)}
                  alt={`Brand ${index + 1}`}
                  className={styles.image}
                />
              </div>
            ))}
            {/* Duplicate the images for infinite scroll effect */}
            {brandImages.map((image, index) => (
              <div key={index + brandImages.length} className={styles.imageContainer}>
                <img
                  src={require(`../../assets/brands/${image}`)}
                  alt={`Brand ${index + 1}`}
                  className={styles.image}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* Awards */}
      <div className={styles.awards}>
        <h1>AWARDS</h1>
        <div className={styles.carouselContainer}>
          <div ref={carouselAwardsRef} className={styles.carousel_award}>
            {awardsImages.map((image, index) => (
              <div key={index} className={styles.imageContainer}>
                <img
                  src={require(`../../assets/awards/${image}`)}
                  alt={`Award ${index + 1}`}
                  className={styles.award_image}
                />
              </div>
            ))}
            {/* Duplicate the images for infinite scroll effect */}
            {awardsImages.map((image, index) => (
              <div key={index + awardsImages.length} className={styles.imageContainer}>
                <img
                  src={require(`../../assets/awards/${image}`)}
                  alt={`Award ${index + 1}`}
                  className={styles.award_image}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AwardsAndBrands;
