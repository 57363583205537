import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import styles from "./ServicesProvider.module.css";
import Loader from "../Loader/Loader";
import hoardings from "../../services/hoardings.json";
import hoardingBanner from "../../assets/hoardings/S 01.jpg";
import ImageSlider from "../imageSlider";

function ServicesProvide() {
  const [loading, setLoading] = useState(true);
  const { state } = useLocation();
  const serviceData = state?.service;
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [rotatingImages, setRotatingImages] = useState(true);

  useEffect(() => {
    setLoading(true);
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000);
    return () => clearTimeout(timer);
  }, [serviceData]);

  useEffect(() => {
    if (serviceData?.services && rotatingImages) {
      const interval = setInterval(() => {
        setCurrentImageIndex(
          (prevIndex) => (prevIndex + 1) % serviceData.services.length
        );
      }, 2000);

      return () => clearInterval(interval);
    }
  }, [serviceData, rotatingImages]);

  useEffect(() => {
    if (!rotatingImages) {
      const timeout = setTimeout(() => {
        setRotatingImages(true);
        setCurrentImageIndex(
          (prevIndex) => (prevIndex + 1) % serviceData.services.length
        );
      }, 4000);

      return () => clearTimeout(timeout);
    }
  }, [rotatingImages, serviceData]);

  const handleServiceClick = (index) => {
    setCurrentImageIndex(index);
    setRotatingImages(false);
  };

  const bannerImagePath = require(`../../assets/serviceBanners/${serviceData.bannerImage}`);

  if (!serviceData) {
    return <div>Service not found</div>;
  }

  return (
    <>
      {loading && <Loader />}
      {!loading && (
        <div className="d-flex flex-column bg-red h-100">
          <div
            className={`${styles.banner} position-relative d-flex align-items-center justify-content-center`}
           
          >
            <div
              className={`${styles.overlay} position-absolute w-100 h-100`}
            ></div>
            <img
              className={`${styles.bannerImage} position-absolute w-100`}
              src={
                serviceData.title !== "Rental Hoardings"
                  ? bannerImagePath
                  : hoardingBanner
              }
              alt="Service Banner"
            />
            <div className={`${styles.title}`}>{serviceData.title}</div>
          </div>

          <div className={`${styles.title_description_services}`}>
            <div className={`${styles.desc_container}`}>
              <div
                className={`${styles.description} d-flex justify-content-center text-white`}
              >
                {serviceData.description}
              </div>
              {serviceData.title === "Branding" && (
                <>
                <div className={styles.branding}>
                <ImageSlider/>

                </div>
                </>
              )}
              {serviceData.title === "Rental Hoardings" ? (
                <div className={`${styles.horizontal_images_container} mt-5`}>
                  <div className={`${styles.horizontal_images_wrapper}`}>
                    {hoardings.map((hoarding, index) => (
                      <img
                        key={hoarding.id}
                        src={require(`../../assets/hoardings/${hoarding.src}`)}
                        className={styles.horizontal_image}
                        alt={`Service ${hoarding.id}`}
                      />
                    ))}
                    {hoardings.map((hoarding, index) => (
                      <img
                        key={hoarding.id}
                        src={require(`../../assets/hoardings/${hoarding.src}`)}
                        className={styles.horizontal_image}
                        alt={`Service ${hoarding.id}`}
                      />
                    ))}
                  </div>
                </div>
              ) : (
                <div className={`${styles.services_images_list} mt-5`}>
                  <div className={`${styles.imageContainer}`}>
                    <img
                      src={require(`../../assets/serviceBanners/${serviceData.services[0].image}`)}
                      className={`${styles.image}`}
                      alt="Service"
                    />
                  </div>
                  <div className={`d-flex ${styles.li_div} text-dark`}>
                    <ul className={`${styles.list} d-flex flex-wrap`}>
                      {serviceData.services.map((service, index) => (
                        <li
                          key={index}
                        >
                          {service.service}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default ServicesProvide;
