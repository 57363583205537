import React from 'react';
import styles from './workImages.module.css';
import { useLocation } from 'react-router-dom';

function WorkImages() {
    const { state } = useLocation();
    const workName = state?.workName;
    const images = workName && Array.isArray(workName.images) ? workName.images : [];

    const videos = [
        "Cfql-fPwrmk",
        "QwyA8cI1rLM",
        "auRTLeFW3eY",
        "Gtp9OI5Pz4c",
        "28ay5O59S4M",
        "_TCJddddtxE",
        "O4CD2aGlsx8",
        "jdmOTAvQvac",
        "4W1r0WFcytc",
        "7zYbehcC8xY"
    ];

    const getImagePath = (image) => {
        let imagePath = image; // Default to the image path directly

        // Adjust image path based on workName.workName
        if (workName) {
            if (workName.workName === 'Print') {
                imagePath = require(`../../assets/print/${image}`);
            } else if (workName.workName === 'Event') {
                imagePath = require(`../../assets/events/${image}`);
            } else if (workName.workName === 'All') {
                imagePath = require(`../../assets/All/${image}`);
            }
        }

        return imagePath;
    };

    return (
        <div className={styles.workContainer}>
            <h1>{workName && workName.workName}</h1>
            
            {/* Render images if workName.workName is Print, Event, or All */}
            {(workName?.workName === 'Print' || workName?.workName === 'Event' || workName?.workName === 'All') && (
                <div className={styles.parent}>
                    {images.map((image, index) => (
                        <div key={index} className={styles.image}>
                            <img src={getImagePath(image)} alt={`Placeholder ${index + 1}`} />
                        </div>
                    ))}
                </div>
            )}

            {/* Render videos if workName.workName is video-and-tvc */}
            {workName?.workName === 'Video and TVC' && (
                <div className={styles.video_parent}>
                    {/* <div className="video-list"> */}
                        {videos.map((videoId, index) => (
                            <div key={index} className={styles.videogrid}>
                                <iframe
                                    height="315"
                                    width="100%"
                                    src={`https://www.youtube.com/embed/${videoId}`}
                                    frameBorder="0"
                                    allowFullScreen
                                    title={`Relations Media Video ${videoId}`}
                                ></iframe>
                            </div>
                        ))}
                    {/* </div> */}
                </div>
            )}
        </div>
    );
}

export default WorkImages;
