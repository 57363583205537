import React from 'react';
import styles from './storyAndTeam.module.css';
import teamImg1 from '../../assets/team/TEAM 04.jpg'
import teamImg2 from '../../assets/team/TEAM 02.jpg'
import teamImg3 from '../../assets/team/TEAM 03.jpg'

function StoryAndTeam() {
    return (
        <div className={styles.container}>
            <h1>Story and Team</h1>
            <section className={`${styles.section} ${styles.reverse_section}`}>
                <div className={styles.image}>
                    <img
                        src={teamImg1}
                        alt="Image 1"
                    />
                </div>
                <div className={styles.text}>
                    <p>From our humble beginnings, we have grown into a dynamic and diverse team, driven by a commitment to innovation and excellence. Our team’s approach is always grounded in our four core values.</p>
                </div>
            </section>
            <section className={styles.section}>
                <div className={styles.text}>
                    <p>
                        <p className={styles.story_title}>Collaborative Ideation</p><br />
                        Collaboration is at the heart of our process. We immerse ourselves in understanding our clients' goals, challenges, and visions.<br /><br />
                        <p className={styles.story_title}> Strategic Planning</p><br />
                        We align our creative concepts with strategic insights to ensure that our campaigns are not only captivating but also effective in achieving our clients' objectives.
                    </p>
                </div>
                <div className={styles.image}>
                    <img
                        src={teamImg2}
                        alt="Image 2"
                    />
                </div>
            </section>
            <section className={`${styles.section} ${styles.reverse_section}`}>
                <div className={styles.image}>
                    <img
                        src={teamImg3}
                        alt="Image 3"
                    />
                </div>
                <div className={styles.text}>
                    <p><p className={styles.story_title}>Creative Execution</p> <br />
                        With a talented team of designers, writers, and digital experts, we bring our concepts to life.<br /><br />
                        <p style={{"fontSize":"1.5rem"}}> Continuous Improvement and Innovation</p><br />
                        We believe in continuous improvement. We ensure that we consistently deliver innovative and impactful solutions for our clients.</p>
                </div>
            </section>
        </div>
    );
}

export default StoryAndTeam;
