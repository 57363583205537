import React, { useState, useEffect } from "react";
import emailjs from "emailjs-com";
import styles from "./contact.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faPhone,
  faEnvelope,
  faAngleRight,
} from "@fortawesome/free-solid-svg-icons";
import {
  faInstagram,
  faLinkedin,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import Portal from "../Portal";

const alertContainerStyle = {
  position: "fixed",
  top: 0,
  left: "50%",
  transform: "translateX(-50%)",
  width: "100%",
  maxWidth: "600px",
  zIndex: 1000,
  padding: "10px",
};

function ContactForm() {
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState("");

  const SERVICE_ID = process.env.REACT_APP_SERVICE_ID;
  const TEMPLATE_ID = process.env.REACT_APP_TEMPLATE_ID;
  const PUBLIC_KEY = process.env.REACT_APP_PUBLIC_KEY;

  useEffect(() => {
    if (alertMessage) {
      const timer = setTimeout(() => {
        setAlertMessage("");
        setAlertType("");
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [alertMessage]);

  const redirectToYouTube = () => {
    window.open(
      "https://www.youtube.com/@RelationsMediaBroadcasting",
      "_blank"
    );
  };

  const redirectToLinkedIn = () => {
    window.open("https://www.linkedin.com/company/relationsmedia/", "_blank");
  };

  const redirectToInstagram = () => {
    window.open(
      "https://www.instagram.com/relations.media?igsh=MXRiNmpibnZjdzYxMg==",
      "_blank"
    );
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    emailjs.sendForm(SERVICE_ID, TEMPLATE_ID, e.target, PUBLIC_KEY).then(
      (result) => {
        setAlertMessage("Message Sent Successfully");
        setAlertType("success");
      },
      (error) => {
        setAlertMessage("Something went wrong!");
        setAlertType("danger");
      }
    );
    e.target.reset();
  };

  return (
    <>
      {alertMessage && (
        <Portal>
          <div
            className={`alert alert-${alertType}`}
            role="alert"
            style={alertContainerStyle}
          >
            {alertMessage}
          </div>
        </Portal>
      )}
      <div className={styles.wrapper}>
        <section className={styles.section}>
          <div className={styles.sectionHeader}>
            <h1 className="text-white mb-3">Connect with us</h1>
          </div>

          <div className={styles.container}>
            <div className={styles.row}>
              <div className={styles.contactInfo}>
                <div className={styles.contactInfoItem}>
                  <div className={styles.contactInfoIcon}>
                    <FontAwesomeIcon icon={faHome} />
                  </div>
                  <div className={styles.contactInfoContent}>
                    <h4>Address</h4>
                    <p>
                      Relations Media
                      <br />
                      TC 9/350-1,O street-12,
                      <br />
                      Jawahar Nagar,&nbsp;Kowdiar
                      <br />
                      Thiruvananthapuram
                      <br />
                      695003
                    </p>
                  </div>
                </div>

                <div className={styles.contactInfoItem}>
                  <div className={styles.contactInfoIcon}>
                    <FontAwesomeIcon icon={faPhone} />
                  </div>
                  <div className={styles.contactInfoContent}>
                    <h4>Phone</h4>
                    <p>0471-2310632</p>
                  </div>
                </div>

                <div className={styles.contactInfoItem}>
                  <div className={styles.contactInfoIcon}>
                    <FontAwesomeIcon icon={faEnvelope} />
                  </div>
                  <div className={styles.contactInfoContent}>
                    <h4>Email</h4>
                    <p>relationsmedia9@gmail.com</p>
                  </div>
                </div>

                <div className={`${styles.contactInfoItem} ${styles.links}`}>
                  <p>Follow us on</p>
                  <div
                    className={styles.contactInfoIcon}
                    onClick={redirectToInstagram}
                  >
                    <FontAwesomeIcon icon={faInstagram} />
                  </div>
                  <div
                    className={styles.contactInfoIcon}
                    onClick={redirectToLinkedIn}
                  >
                    <FontAwesomeIcon icon={faLinkedin} />
                  </div>
                  <div
                    className={styles.contactInfoIcon}
                    onClick={redirectToYouTube}
                  >
                    <FontAwesomeIcon icon={faYoutube} />
                  </div>
                </div>
              </div>

              <div className={styles.contactForm}>
                <form id="contact-form" onSubmit={handleOnSubmit}>
                  <h2>Send Message</h2>
                  <div className={styles.inputBox}>
                    <input
                      type="text"
                      id="from_name"
                      name="from_name"
                      placeholder="Full Name"
                      required
                    />
                  </div>

                  <div className={styles.inputBox}>
                    <input
                      type="email"
                      id="from_email"
                      name="from_email"
                      placeholder="Email"
                      required
                    />
                  </div>

                  <div className={styles.inputBox}>
                    <textarea
                      id="message"
                      name="message"
                      required
                      placeholder="Enter your message"
                    ></textarea>
                  </div>

                  <div className={styles.inputBox}>
                    <button type="submit" className={styles.view_more_btn}>
                      <div className={styles.view_more_btn_text}>
                        <p>Send</p>
                      </div>
                      <FontAwesomeIcon
                        icon={faAngleRight}
                        className="icon-right"
                      />
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default ContactForm;
