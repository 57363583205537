import React from 'react'
import ContactForm from './ContactForm'
import styles from './contactLocation.module.css'

function Contact_Location() {
  return (
   <>
   <div className={`${styles.title_description_services}`}>
          <div className={`${styles.desc_container}`}>
            <div className={`${styles.contact_content} d-flex`}>
             
              <div className={`col-md-4 ${styles.map}`}>
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15783.2538683301!2d76.9693393!3d8.5174841!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b05bb262a74d7bf%3A0x78dad7e390187db3!2sRelations%20Media!5e0!3m2!1sen!2sin!4v1717673818129!5m2!1sen!2sin"
                  width="100%"
                  height="100%"
                  style={{ border: 0 }}
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
              <div className={`col-md-8 ${styles.form}`}>
                <ContactForm />
              </div>
            </div>
          </div>
        </div>
   </>
  )
}

export default Contact_Location