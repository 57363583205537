import React, { useState } from "react";
import "../styles/navbar.css";
import Logo from "../assets/images/relationsmedia-logo-120x120.png";
import Menu from "./Menu";
import { HashLink } from "react-router-hash-link";

function Navbar() {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen); 
  };
 

  return (
    <>
      <nav className="nav d-flex justify-content-between px-3 bg-transparent position-fixed z-index-5 w-100 ">
        <div>
          <HashLink to="/#">
            <img className="rm-logo" src={Logo} alt="Logo not found"  onClick={()=>setMenuOpen(false)}></img>
          </HashLink>
        </div>
        <div className="menu-toggle" onClick={toggleMenu}>
          <button>
            <span></span>
            <span></span>
            <span></span>
          </button>
        </div>
      </nav>
      <Menu isOpen={menuOpen} onClose={() => setMenuOpen(false)} />
    </>
  );
}

export default Navbar;
