import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home/Home";
import ServicesProvide from "./components/services-details/ServicesProvide";
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import AboutSection from "./pages/About Section/AboutSection";
import Careers from "./pages/Careers/Careers";
import WorkImages from "./pages/workImages/WorkImages";
import StoryAndTeam from "./pages/Story/StoryAndTeam";

function App() {
  return (
    <div className="d-flex flex-column min-vh-100">
    <BrowserRouter>
      <Navbar />
      <div className="d-flex flex-grow-1  flex-column">
        <Routes>
          <Route exact path="/" element={<Home />} />
          {/* Change the path with service clicked */}
          <Route path="/services/:service_name" element={<ServicesProvide />} />
          <Route path="/works/:workname" element={<WorkImages/>}/>
          <Route path="/story" element={<StoryAndTeam/>}/>
          <Route path="/about" element={<AboutSection />} />
          <Route path="/contact"  element={<Home/>}/>
          <Route path="/careers" element={<Careers/>}/>
        </Routes>
      </div>
    </BrowserRouter>
    <Footer />
  </div>
  );
}

export default App;
