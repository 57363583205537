import React from 'react';

function Footer() {
  return (
    <div style={{ textAlign: 'center', padding: '20px 10px', backgroundColor: 'rgb(41 39 39)', color: 'white', fontSize: '14px' }}>
      &copy; {new Date().getFullYear()} Relations Media. All rights reserved.
    </div>
  );
}

export default Footer;
